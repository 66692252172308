//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    content: {
      type: String,
      "default": 'xxx' },

    btn: {
      type: String,
      "default": '关闭' } },


  computed: {
    style: function style() {
      return {};
    } },

  methods: {
    handleClose: function handleClose(e) {
      e.preventDefault();
      this.$emit('close');
    } } };