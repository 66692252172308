var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "notify", style: _vm.style },
      [
        _vm._t("default", [
          _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
          _c("a", { staticClass: "btn", on: { click: _vm.handleClose } }, [
            _vm._v(_vm._s(_vm.btn))
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }